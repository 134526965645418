<template>
  <div class="inner-balance">
    <div class="inner-balance__title">
      {{ $t('dashboardInfo.innerBalance.title') }}
    </div>
    <p class="inner-balance__text">
      {{ $t('dashboardInfo.innerBalance.text') }}
    </p>

    <div class="inner-balance__content" v-if="isOpen">
      <div class="inner-balance__current">
        <span class="inner-balance__current-wallet">{{
          $t('dashboardInfo.innerBalance.curWallet')
        }}</span>
        <p class="inner-balance__wallet">
          {{ walletShort }}
          <BaseIcon
            class="copy"
            name="copy"
            verAlign
            @event="handleClipboardCopy(user.account)"
          />
        </p>
      </div>
      <div class="inner-balance__additional">
        <span class="inner-balance__extra-wallet">{{
          $t('dashboardInfo.innerBalance.addWallet')
        }}</span>
        <form class="inner-balance__form-group" @submit.prevent="handleSubmit">
          <input
            class="inner-balance__input"
            type="text"
            name="wallet"
            :placeholder="$t('dashboardInfo.innerBalance.inputPlaceholder')"
            required
            v-model="wallet"
          />
          <ButtonLight
            :text="$t('dashboardInfo.innerBalance.buttonApply')"
            :classes="'inner-balance__button'"
          />
        </form>
      </div>
      <p class="inner-balance__alert">
        {{ $t('dashboardInfo.innerBalance.alertText') }}
      </p>
    </div>
    <div class="inner-balance__btn-inner">
      <ButtonDark
        :text="
          !isOpen
            ? $t('dashboardInfo.innerBalance.buttonAdd')
            : $t('dashboardInfo.innerBalance.buttonHide')
        "
        @event="isOpen = !isOpen"
      />
    </div>
  </div>
</template>

<script>
import ButtonDark from '@/components/UI/ButtonDark.vue'
import ButtonLight from '@/components/UI/ButtonLight.vue'
import BaseIcon from '@/components/UI/BaseIcon.vue'

export default {
  props: {
    user: Object,
  },

  components: {
    ButtonDark,
    ButtonLight,
    BaseIcon,
  },

  data() {
    return {
      isOpen: false,
      wallet: '',
    }
  },

  methods: {
    handleClipboardCopy(text) {
      if (!text) return

      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$toast.success(`${text} ${this.$t('account.copied')}`)
        })
        .catch(() => {
          this.$toast.error(`${this.$t('account.copyError')}`)
        })
    },

    handleSubmit() {
      console.log('Success!')
    },
  },

  computed: {
    walletShort() {
      const userWallet = this.user.account
      const walletLength = userWallet.length

      return `${userWallet.slice(0, 7)}...${userWallet.slice(-9, walletLength)}`
    },
  },
}
</script>

<style>
.copy {
  margin-left: 2px;
  margin-top: 2px;
}
</style>
