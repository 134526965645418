<template>
    <div v-if="!loading && getToken">
        <div class="dashboard__inner">
            <div class="dashboard__col-left">
                <UserWidget :user="user"/>
            </div>
            <div class="dashboard__col-right">
                <AccInfo :user="user" />
                <ActivityHistory :user="user" />
            </div>
        </div>
        <!-- <InfoBlock /> -->
        <!-- <Systems :user="user" /> -->
        <!-- <RoadMap /> -->
    </div>

    <div v-else>
        <UiLoader theme="block" :loading="true" />
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import pageReadyByConnect from "@/mixin/pageReadyByConnect"

import AccInfo from "@/components/Dashboard/AccInfo.vue"
import Systems from "@/components/Dashboard/Systems.vue"
import InfoBlock from "@/components/Dashboard/InfoBlock.vue"
import ActivityHistory from "@/components/Dashboard/ActivityHistory.vue"
import UserWidget from '@/components/Dashboard/UserWidget.vue'

// import RoadMap from "@/components/Dashboard/RoadMap.vue"

export default {
    components: {
        Systems,
        AccInfo,
        InfoBlock,
        ActivityHistory,
        UserWidget,
        // RoadMap,
    },
    mixins: [pageReadyByConnect],
    data() {
        return {
            loading: true,
            error: false,
            user: null
        }
    },
    computed: {
        ...mapGetters("crypto", ["getAccount", "isConnected", "getToken"])
    },
    async mounted() {
        console.log('dashboard mounted')
        if (this.isConnected) {
            await this.pageReady()
        }
    },
    methods: {
        ...mapActions("user", ["guessUser", "saveUser"]),
        ...mapActions("crypto", ["checkRegistration", "getProgramLevels"]),
        ...mapMutations("user", ["setClassicMatrix", "updateClassicMatrixLevels"]),
        async pageReady() {
            const [regErr, regParent] = await this.checkRegistration(true)
            if (regErr) {
                this.$swal(this.$t("errors.registrationError"))
                await this.$router.push({ name: "signup" })
                return
            }

            await this.fetchAccount()
        },
        async fetchAccount() {
            this.loading = true
            this.error = false

            const accountQuery = this.$route.query.account
            const targetAccount = accountQuery || this.getAccount

            if (!targetAccount) return

            try {
                let userResponse = await this.guessUser(targetAccount)
                if (!userResponse) throw new Error()

                this.setClassicMatrix(userResponse.classic)
                const [err, levels] = await this.getProgramLevels(userResponse.account)
                this.updateClassicMatrixLevels(levels)

                this.user = userResponse
                this.saveUser(userResponse)
            } catch (err) {
                this.$swal(this.$t("errors.userNotFound"))
                this.error = this.$t("errors.userNotFound")
                await this.$router.push({ name: "dashboard" })
            }

            this.loading = false
        }
    },
}
</script>
<style lang="scss" scoped>
.main {
    width: 100%;
    @media screen and (max-width: 960px) {
        padding: 0 20px;
    }
}
</style>
