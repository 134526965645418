<template>
    <div class="modal">
        <button type="button" class="close" @click="closeModal"><svg-vue icon="close" /></button>
        <h2 class="modal__title">{{ $t("dashboardBalanceModal.title") }}</h2>
        <div class="modal__body">
            <p>
                {{ $t("dashboardBalanceModal.content") }}
            </p>

            <form>
                <div class="form-group">
                    <label for="email">{{ $t("dashboardBalanceModal.email") }}</label>
                    <input type="email" id="email" name="email" v-model="email" required />
                </div>
                <div class="form-group">
                    <label for="wallet">{{ $t("dashboardBalanceModal.newWallet") }}</label>
                    <input type="text" id="wallet" name="wallet" v-model="newWallet" required />
                </div>
                <div class="form-group">
                    <input type="checkbox" id="agreement" name="agreement" required @change="checked = !checked" />
                    <label for="agreement">
                        {{ $t("dashboardBalanceModal.rules") }}
                    </label>
                </div>
                <button
                    class="btn"
                    :class="checked ? 'btn-default' : 'btn-disabled'"
                    :disabled="!checked"
                    @click="sendForm"
                >
                    {{ $t("dashboardBalanceModal.send") }}
                </button>
            </form>
        </div>
    </div>
</template>
<script>
import axios from "axios"
import web3 from "web3"

export default {
    data() {
        return {
            email: "",
            newWallet: "",
            checked: false
        }
    },
    methods: {
        getAccount() {
            return web3.utils.toChecksumAddress(this.$store.getters["crypto/getAccount"])
        },
        sendForm(e) {
            e.preventDefault()
            const account = this.getAccount()
            const url = "https://web3up.net/api/hacking-wallet"
            axios
                .post(url, {
                    email: this.email,
                    oldWallet: account,
                    newWallet: this.newWallet,
                    // eslint-disable-next-line
                    "csrf-token": csrf_token
                })
                .then((res) => {
                    console.log(res)
                    this.closeModal()
                    res.data.status
                        ? this.$toast.success(`${this.$t("dashboardBalanceModal.resultRequest")}`)
                        : this.$toast.error(res.data.message)
                    this.$emit("resultRequest", {
                        success: res.data.success,
                        email: this.email,
                        newWallet: this.newWallet
                    })
                })
                .catch((err) => {
                    this.closeModal()
                    this.$toast.error(`${err.response.data.globalError.message}`)
                })
        },
        closeModal() {
            this.$emit("closeModal", false)
        }
    }
}
</script>
<style lang="scss" scoped>
.modal {
    width: calc(100% - 30px);
    max-width: 566px;
    margin: 0 auto;
    padding: 48px 0 40px;
    position: fixed;
    left: 0;
    right: 0;
    border-radius: 30px;
    background: #161620;
    color: #ffffff;
    overflow: scroll;

    .close {
        position: absolute;
        top: 25px;
        right: 54px;
        background: transparent;
        border: 0;
        cursor: pointer;
    }

    .modal__title {
        margin: 0 0 12px;
        padding: 0 94px;
        font-weight: 600;
        font-size: 30px;
        line-height: 45px;
        text-align: center;
    }

    .modal__body {
        padding: 0 74px 0 94px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        p {
            margin: 0 0 22px;
        }

        form {
            margin-bottom: 0;

            .form-group {
                display: flex;
                flex-direction: column;

                input[type="text"],
                input[type="email"] {
                    width: 100%;
                    height: 38px;
                    padding: 8px;
                    background: #23222c;
                    border-radius: 10px;
                    border: none;
                    color: #ffffff;
                }

                label {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #828282;
                    margin-bottom: 8px;
                }

                &:nth-last-child(2) {
                    flex-direction: row;
                    align-items: flex-start;

                    label {
                        margin-left: 8px;
                        font-size: 14px;
                        line-height: 21px;
                        color: #ffffff;
                    }
                }
            }

            .form-group + .form-group {
                margin-top: 12px;
            }

            .btn {
                margin-top: 8px;
            }
        }
    }

    &-sm {
        padding: 72px 0 40px;
        position: relative;
        text-align: center;

        .btn {
            margin-top: 40px;
        }
    }
}
.btn {
    height: 33px;
    padding: 0 30px;
    font-size: 17px;
    line-height: 33px;
    border: 0;
    border-radius: 10px;
    cursor: pointer;

    &-disabled {
        width: 144px;
        height: 35px;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.4);
        background: rgba(255, 255, 255, 0.17);
        pointer-events: none;
        cursor: default;
    }

    &-active {
        color: rgba(0, 0, 0, 0.8);
        background: #38c948;
        pointer-events: auto;
    }

    &-default {
        width: 144px;
        height: 35px;
        font-size: 16px;
        line-height: 24px;
        background: #fedd00;
    }
}
@media screen and (max-width: 460px) {
    .modal {
        overflow: scroll;
        .close {
            top: 15px;
            right: 15px;
        }
        .modal__title {
            padding: 0 5px;
            font-size: 20px;
            line-height: 35px;
        }
        .modal__body {
            padding: 0 20px 5px 20px;
            text-align: center;
            form {
                .form-group {
                    input {
                        margin-right: 0;
                    }
                    label {
                        text-align: left;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
</style>
