<template>
    <section id="balance">
        <!--BALANCE DEFAULT-->
        <div class="balance" v-if="show">
            <div class="balance__title">
                {{ $t("dashboardBalance.title") }}
                <button type="button" class="tooltip">
                    <span class="balance__tooltip" :content="$t(`dashboardBalance.tooltip`)" v-tippy>
                        <svg-vue icon="info-balance" />
                    </span>
                </button>
            </div>
            <div class="balance__sum">
                <div class="balance__sum-amount">
                    <svg-vue icon="dai" />
                    {{ total ? total : 0 }} DAI
                </div>
                <button
                    type="button"
                    class="btn btn-active"
                    v-if="resultAccountTree && !activeWithdraw && !withdrawSuccess"
                    @click="withdrawMoney()"
                >
                    {{ $t("dashboardBalance.withdraw") }}
                </button>
            </div>
            <div class="balance__address" :class="refundData ? 'changed' : ''">
                {{ !refundData ? $t("dashboardBalance.walletAccount") : $t("dashboardBalance.changeAddress") }}
                <span>{{ refundData ? refundData.wallet : account }}</span>
                {{ refundData ? refundData.email : "" }}
            </div>
            <p v-if="!withdrawSuccess" @click="showModal = true" class="balance__call-modal">
                <span>{{ $t("dashboardBalance.hackedWallet") }}</span> <svg-vue icon="write" />
            </p>
            <div class="balance__success" v-if="withdrawSuccess">
                <svg-vue icon="success" />
                {{ $t("dashboardBalanceModal.successWithdraw") }}
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal modal-sm" v-if="showModalWithdraw">
            <button type="button" class="close" @click="showModalWithdraw = false"><svg-vue icon="close" /></button>
            <div class="modal__body">
                {{ $t("dashboardBalance.withdrawModer") }}
            </div>
            <button type="button" class="btn btn-default" @click="showModalWithdraw = false">
                {{ $t("dashboardBalance.successBtn") }}
            </button>
        </div>
        <HackedModal v-if="showModal" @closeModal="closeModal" @resultRequest="resultHackedModal" />
    </section>
</template>

<script>
import axios from "axios"
import HackedModal from "./HackedWalletModal.vue"
import web3 from "web3"
export default {
    methods: {
        getAccount() {
            return web3.utils.toChecksumAddress(this.$store.getters["crypto/getAccount"])
        },
        resultHackedModal() {
            this.render()
        },
        async withdrawMoney() {
            const account = this.getAccount()
            await this.$store.dispatch("crypto/withdrawMoney", {
                index: this.resultAccountTree.index,
                address: account,
                amount: this.resultAccountTree.amount,
                merkleProof: this.resultAccountTree.proof,
                sum: this.total,
            })
        },
        async render() {
            const account = this.getAccount()
            const url = `https://web3up.net/api/hung-money/${account}`
            const urlPayment = `https://web3up.net/api/get-payment-tree/${account}`
            axios.get(url).then((res) => {
                if (res.data.status === 200) {
                    this.show = true
                    this.total = res.data.data.total
                    this.withdrawSuccess = res.data.withdraw
                    if (res.data.data.refund_status) {
                        this.refundData = {
                            email: res.data.data.refund_data.email,
                            wallet: res.data.data.refund_data.new_wallet,
                        }
                    }
                }
            })
            const resultPayment = await axios.get(urlPayment)
            if (resultPayment) {
                this.resultAccountTree = resultPayment.data
                this.activeWithdraw = await this.$store.dispatch("crypto/checkWithdraw", account)
            }
        },
        closeModal(payload) {
            this.showModal = payload
        },
    },
    components: {
        HackedModal,
    },
    data() {
        this.render()
        return {
            withdrawSuccess: false,
            activeWithdraw: true,
            resultAccountTree: {},
            refundData: false,
            showModal: false,
            showModalWithdraw: false,
            show: false,
            total: null,
            message: "Money mistakenly frozen in the first days of launching the system",
            account: this.getAccount(),
        }
    },
}
</script>
<style lang="css" scoped>
.break-button {
    margin-left: 10px;
    padding: 5px 20px;
    background: #eacd45;
    color: black;
    border: none;
    border-radius: 10px;
}

p.price {
    font-size: 20px;
}

#infoBlock {
    width: 400px;
    display: block !important;
}
</style>

<style lang="scss" scoped>
*,
::after,
::before {
    box-sizing: border-box;
    outline: none;
}

#balance {
    margin-bottom: 30px;
}

.balance {
    display: flex;
    flex-direction: column;
    max-width: 442px;
    width: 100%;
    min-height: 226px;
    padding: 20px 30px;
    background: #161620;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #828282;

    &__title {
        display: flex;
        align-items: center;
        margin-bottom: 14px;

        .tooltip {
            width: 18px;
            height: 18px;
            margin-left: 10px;
            padding: 0;
            border-radius: 50%;
            border: 0;
            background: none;
            cursor: pointer;
        }
    }

    &__sum {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;

        &-amount {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 22px;
            line-height: 33px;
            color: #ffffff;

            svg {
                margin-right: 8px;
            }
        }
    }

    &__address {
        display: flex;
        flex-direction: column;

        span {
            margin-top: 10px;
            font-size: 12px;
            line-height: 18px;
        }

        &.changed {
            color: #fedd00;

            span {
                margin: 10px 0 6px;
                font-size: 16px;
                line-height: 19px;
            }
        }
    }

    &__tooltip {
        cursor: pointer;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-silver);
    }

    &__call-modal {
        display: flex;
        margin-top: 16px;
        text-decoration: none;
        color: #ffffff;
        cursor: pointer;

        span {
            text-decoration: underline;
        }

        svg {
            margin-left: 10px;
        }
    }

    &__success {
        display: flex;
        align-items: center;
        color: #38c948;
        line-height: 24px;
        margin-top: 16px;

        svg {
            margin-right: 8px;
        }
    }
}
.btn {
    height: 33px;
    padding: 0 30px;
    font-size: 17px;
    line-height: 33px;
    border: 0;
    border-radius: 10px;
    cursor: pointer;

    &-disabled {
        color: rgba(255, 255, 255, 0.4);
        background: rgba(255, 255, 255, 0.17);
        pointer-events: none;
        cursor: default;
    }

    &-active {
        color: rgba(0, 0, 0, 0.8);
        background: #38c948;
        pointer-events: auto;
    }

    &-default {
        width: 144px;
        height: 35px;
        font-size: 16px;
        line-height: 24px;
        background: #fedd00;
    }
}
.modal {
    width: calc(100% - 30px);
    max-width: 566px;
    margin: 0 auto;
    padding: 48px 0 40px;
    position: fixed;
    left: 0;
    right: 0;
    border-radius: 30px;
    background: #161620;
    color: #ffffff;

    .close {
        position: absolute;
        top: 25px;
        right: 54px;
        background: transparent;
        border: 0;
        cursor: pointer;
    }
    .modal__body {
        padding: 0 74px 0 94px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
    }

    &-sm {
        top: 30%;
        height: 230px;
        padding: 72px 0 40px;
        text-align: center;

        .btn {
            color: #000;
            margin-top: 40px;
        }
    }
}
@media screen and (max-width: 460px) {
    .balance {
        height: 280px;

        &__sum {
            flex-direction: column;
            align-items: flex-start;
            grid-row-gap: 13px;
        }

        &__address {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    .modal {
        .modal-sm {
            top: 25%;
            height: 240px;
            padding: 62px 0 0;
        }
        .modal__body {
            padding: 0;
        }
        .close {
            top: 15px;
            right: 15px;
        }
    }
}
</style>
