<template>
    <section class="systems">
        <header class="systems-header">
            <h2 class="systems__title section-title">{{ $t("systems.title") }}</h2>
        </header>
        <div class="systems-items">
            <!-- <div class="systems-item-wrapper systems-item-wrapper--referal">
                <div class="systems-item systems-item--referal">
                    <div class="systems-item__left">
                        <h3 class="systems-item__title">Referral</h3>
                        <div class="systems-more-wrapper systems-more-wrapper--desktop">
                            <router-link :to="{ name: 'systems_referral' }" class="systems-item__more">
                                {{ $t("systems.more") }}
                                <svg-vue icon="out-dark" />
                            </router-link>
                        </div>
                    </div>
                    <div class="systems-item__right">
                        <svg-vue icon="referal-tree" />
                    </div>
                    <div class="systems-more-wrapper systems-more-wrapper--mobile">
                        <router-link :to="{ name: 'systems_referral' }" class="systems-item__more">
                            {{ $t("systems.more") }}
                            <svg-vue icon="out-dark" />
                        </router-link>
                    </div>
                </div>
            </div> -->
            <div class="systems-item-wrapper systems-item-wrapper--classic">
                <div class="systems-item systems-item--classic">
                    <div class="systems-item__left">
                        <h3 class="systems-item__title">Classic</h3>
                        <!-- <h4 class="systems-item__value">todo DAI</h4> -->
                        <div class="systems-more-wrapper systems-more-wrapper--desktop">
                            <router-link :to="{ name: 'classic' }" class="systems-item__more">
                                {{ $t("systems.more") }}
                                <svg-vue icon="out" />
                            </router-link>
                        </div>
                    </div>
                    <div class="systems-item__right">
                        <div
                            class="systems-item__circle"
                            v-for="(item, idx) in classicList"
                            :key="idx"
                            :class="[item.active === true && 'active', item.active === 'gap' && 'gap']"
                        >
                            {{ idx + 1 }}
                        </div>
                    </div>
                    <div class="systems-more-wrapper systems-more-wrapper--mobile">
                        <router-link :to="{ name: 'classic' }" class="systems-item__more">
                            {{ $t("systems.more") }}
                            <svg-vue icon="out" />
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="systems-item-wrapper systems-item-wrapper--boost">
                <div class="systems-item systems-item--boost">
                    <div class="systems-item__left">
                        <h3 class="systems-item__title">Royalty NFT</h3>
                        <router-link :to="{ name: 'nfts' }" class="systems-item__more">
                            {{ $t("systems.more") }}
                            <svg-vue icon="out" />
                        </router-link>
                        <!--                        <h4 class="systems-item__value">{{ $t("systems.comingSoon") }}</h4>-->
                        <!-- <router-link :to="{ name: 'realforce' }" class="systems-item__more">
                            Подробнее
                            <svg
                                width="14"
                                height="15"
                                viewBox="0 0 14 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.19531 12.6953L10.1953 3.69531"
                                    stroke="#161620"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M4.62983 3.69537H10.1983V9.26383"
                                    stroke="#161620"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </router-link> -->
                    </div>
                    <div class="systems-item__right">
                        <div
                            class="systems-item__circle"
                            v-for="n in 8"
                            :key="n"
                            :class="{ active: nftLevels.includes(n - 1) }"
                        >
                            {{ n - 1 }}
                        </div>
                        <!--                        <div class="systems-item__circle">9</div>-->
                        <!--                        <div class="systems-item__circle">10</div>-->
                    </div>
                </div>
            </div>

            <!-- <div class="systems-item d-flex align-center justify-space-between">
          <div class="systems-item__left">
              <h3 class="systems-item__title">Classic</h3>
              <h4 class="systems-item__value">196 DAI</h4>
              <router-link :to="{ name: 'realforce' }" class="systems-item__more d-inline-flex align-center">
                  Подробнее
                  <svg-vue icon="out" />
              </router-link>
          </div>
          <div class="systems-item__right">
              <div
                  class="systems-item__circle d-flex align-center justify-center"
                  v-for="(m, idx) in max"
                  :key="idx"
                  :class="m < 6 ? 'active' : false"
              >
                  {{ m }}
              </div>
          </div>
      </div> -->
        </div>
    </section>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex"
import { getNftLvl } from "@/helpers/nfts"
import nftsApi from "@/api/nftsApi"
import pageReadyByConnect from "@/mixin/pageReadyByConnect"

export default {
    mixins: [pageReadyByConnect],
    data() {
        return {
            nfts: []
        }
    },
    async mounted() {
        this.setBoostMatrix()
        if (this.isConnected) {
            await this.pageReady()
        }
    },
    props: {
        user: Object
    },
    computed: {
        ...mapGetters("crypto", ["getAccount"]),
        classicList() {
            return this.classicMatrix
        },
        boostList() {
            return this.boostMatrix
        },
        nftLevels() {
            let lvls = []
            for (let nft of this.nfts) {
                lvls.push(getNftLvl(nft.contractAddress))
            }
            return lvls
        },
        ...mapState("user", ["classicMatrix", "boostMatrix"])
    },
    methods: {
        ...mapMutations("user", ["setBoostMatrix"]),
        async pageReady() {
            this.loadNfts()
        },
        loadNfts() {
            // this.isNftsLoading = true
            nftsApi
                .nfts({
                    owner: this.user.account.toLowerCase(),
                })
                .then(({ data }) => {
                    this.nfts = data
                    console.log(data)
                })
                .catch((err) => {
                    console.log("err", err)
                })
                .finally(() => {
                    // this.isNftsLoading = false
                })
        }
    }
}
</script>
