<template>
    <section class="history section-offset">
        <div class="history-list-wrapper" ref="list">
            <div class="history-list-header">
                <div class="history-list-header__title">{{ $t("activity_history.title") }}</div>
            </div>
            <ul class="history-list list-reset pa-0">
                <span v-if="transactions.length === 0">{{ $t('activity_history.noTransactionsMessage') }}</span>
                 <li v-for="(transaction, idx) in transactions" :key="idx" class="history-list__item history-item">
                    <div class="history-item__left">
                        <div class="history-item__label">
                            <UiAvatar :user="transaction.user" />
                        </div>
                        <span class="history-item__id">{{ transaction.user.name }}</span>
                        <p class="history-item__desc">{{ transaction.description }}</p>
                    </div>
                    <div class="history-item__right">
                        <a :href="transaction.out" target="_blank" class="history-item__more"
                            ><svg-vue icon="history-more"
                        /></a>
                        <span class="history-item__time">{{ transaction.time }}</span>
                    </div>
                </li>
            </ul>

            <UiLoader v-if="loading" theme="block" :loading="true" />

            <!-- <button class="history-list__show-more btn-reset" @click="showMoreHistory">{{ $t("showMore") }}</button> -->
        </div>
    </section>
</template>

<script>
import { mapActions } from "vuex"
import { isSameAccount, getExplorerLinkByTx } from "@/helpers/user"
import throttle from "lodash/throttle"

export default {
    props: { user: Object },
    data() {
        return {
            loading: false,
            transactions: [],
            meta: {},
        }
    },
    created() {
        this.scrollListWithThrottle = throttle(this.handleListScroll, 300)
    },

    mounted() {
        window.addEventListener("scroll", this.scrollListWithThrottle, false)

        this.fetchData()
    },
    methods: {
        async fetchData(page) {
            this.loading = true
            const { data, meta } = await this.getTransactions({ account: this.user.account, limit: 20, page })

            this.setTransactions(data)
            this.meta = meta

            this.loading = false
        },
        async handleListScroll() {
            if (!this.$refs.list || this.loading) return
            const { current_page, total_pages } = this.meta
            if (current_page >= total_pages) return

            const { top, height } = this.$refs.list.getBoundingClientRect()
            const bottomScroll = top - window.innerHeight
            if (bottomScroll > 0) return

            const scrollRest = Math.abs(bottomScroll) - height

            if (scrollRest >= -300) {
                await this.fetchData(current_page + 1)
            }
        },
        setTransactions(transactions) {
            const sliceTMP = (str) => {
                return `...${str.slice(-4)}`
            }

            const newTransactions = transactions.map((tr) => {
                let descriptionByType = ""

                const isMyOutcoming = isSameAccount(tr.from.account, this.user.account)
                // If not outcoming - than incoming by backend tr.to match
                let targetUser = {}

                if (isMyOutcoming) {
                    targetUser = {
                        isMyAccount: true,
                        name: this.$t("activity_history.your_account"),
                        avatar: tr.from.avatar,
                    }
                } else {
                    targetUser = {
                        // name: sliceTMP(tr.from),
                        name: tr.from.name,
                        avatar: tr.from.avatar,
                    }
                }

                switch (tr.type) {
                    case "registration":
                        descriptionByType = this.$t("activity_history.activated_ref")
                        break

                    case "simpleBuy":
                        descriptionByType = isMyOutcoming
                            ? this.$t("activity_history.you_activated", { lvl: tr.lvl })
                            : this.$t("activity_history.income_ref", { price: tr.price })
                        break
                    default:
                        break
                }

                return {
                    out: getExplorerLinkByTx(tr.transaction_hash),
                    user: targetUser,
                    description: descriptionByType,
                    time: this.$moment(tr.created_at).fromNow(),
                }
            })

            this.transactions = [...this.transactions, ...newTransactions]
        },
        showMoreHistory() {},
        ...mapActions("user", ["getTransactions"]),
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.scrollListWithThrottle, false)
    },
}
</script>
