<template>
  <div class="user-widget">
    <div class="user-widget-user">
      <div class="user-widget-user_avatar">
        <img :src="user.avatar" alt="User" />
      </div>
      <div class="user-widget-user_data">
        <!-- <p class="name">Natalie</p> -->
        <p>
          {{ user.name }}
          <BaseIcon
            class="copy"
            name="copy"
            @event="handleClipboardCopy(user.name)"
          />
        </p>
        <p>
          Wallet: {{ walletShort }}
          <BaseIcon
            class="copy"
            name="copy"
            @event="handleClipboardCopy(user.account)"
          />
        </p>
      </div>
    </div>
    <!-- <div class="user-widget-statistic">
      <p>{{ $t('dashboardInfo.userWidget.followers') }} <span>123</span></p>
      <p>{{ $t('dashboardInfo.userWidget.following') }} <span>1300</span></p>
    </div> -->
    <!-- <ButtonLight
      class="button-light--mr-bottom"
      :text="$t('dashboardInfo.userWidget.buttonLight')"
    />
    <ButtonDark
      class="button-dark--width"
      :text="$t('dashboardInfo.userWidget.buttonDark')"
    />
    <div class="social-links__wrap">
      <SocialLinks />
    </div> -->
    <!-- <div class="user-widget-status">
      {{ $t('dashboardInfo.userWidget.rStatus') }} <span>Ambasador</span>
    </div> -->
    <!-- <div class="user-widget-description">
      {{ $t('dashboardInfo.userWidget.aboutUser') }}
      from these domains
    </div> -->
    <div class="user-widget-upline">
      <div class="user-widget-upline_title">
        {{ $t('dashboardInfo.userWidget.upline') }}
        <BaseIcon
          name="moreInfo"
          :content="$t(`account.upline_tooltip`)"
          v-tippy
        />
      </div>
      <div class="user-widget-upline_user">
        <div class="user__avatar">
          <img :src="user.avatar" alt="User" />
        </div>
        <div class="user__data">
          <!-- <span>Natalie</span> -->
          <!-- <p>Force ID: {{ user.name }}</p> -->
          <p>
            {{ $t('dashboardInfo.userWidget.wallet') }} {{ parentWalletShort }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocialLinks from '@/components/UI/SocialLinks.vue'
import ButtonLight from '@/components/UI/ButtonLight.vue'
import ButtonDark from '@/components/UI/ButtonDark.vue'
import BaseIcon from '@/components/UI/BaseIcon.vue'

export default {
  props: {
    user: Object,
  },

  components: {
    SocialLinks,
    ButtonLight,
    ButtonDark,
    BaseIcon,
  },

  methods: {
    handleClipboardCopy(text) {
      if (!text) return

      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$toast.success(`${text} ${this.$t('account.copied')}`)
        })
        .catch(() => {
          this.$toast.error(`${this.$t('account.copyError')}`)
        })
    },
  },

  computed: {
    walletShort() {
      const userWallet = this.user.account
      const walletLength = userWallet.length

      return `${userWallet.slice(0, 7)}...${userWallet.slice(-9, walletLength)}`
    },
    parentWalletShort() {
      const userWallet = this.user.parent
      const walletLength = userWallet.length

      return `${userWallet.slice(0, 7)}...${userWallet.slice(-9, walletLength)}`
    },
  },
}
</script>

<style scoped>
.social-links__wrap {
  padding: 0 40px;
}
.button-dark--width {
  min-width: 100%;
}
.button-light--mr-bottom {
  margin-bottom: 8px;
}
.copy {
  margin-left: 2px;
  margin-top: 2px;
}
</style>
