<template>
  <div class="force-system">
    <div class="force-system__text">{{ $t('dashboardInfo.forceSystem.text') }}</div>
    <div class="force-system__btn-outer">
      <router-link :to="{ name: 'classic' }">
        <ButtonDark :text="$t('dashboardInfo.forceSystem.button')" />
      </router-link>
    </div>
  </div>
</template>

<script>
import ButtonDark from '@/components/UI/ButtonDark.vue'

export default {
  components: {
    ButtonDark,
  },
}
</script>
