<template>
  <div class="portfolio">
    <div class="main__statistic-tokens">
      <div class="main__statistic-title">
        {{ $t('dashboardInfo.portfolio.title') }}
      </div>
      <ul class="main__statistic-tokens_list">
        <li
          class="main__statistic-tokens_item"
          v-for="(item, idx) in statTokens"
          :key="idx"
        >
          <span class="main__statistic-tokens_item_amount">
            <BaseIcon :name="item.iconName" />
            {{ item.label }}
          </span>
          {{ item.amount }}
          {{
            !item.label.toLowerCase().includes('matic') ? item.label : 'Matic'
          }}
        </li>
      </ul>
    </div>
    <div class="main__statistic-swap">
      <div class="main__statistic-swap-title"></div>
      <div class="main__statistic-swap-inner">
        <ButtonDark
          :text="$t('dashboardInfo.portfolio.button')"
          @event="swap"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'
import ButtonDark from '@/components/UI/ButtonDark.vue'

export default {
  components: {
    BaseIcon,
    ButtonDark,
  },

  props: ['SFCO', 'SFC', 'SFC2'],
  emits: ['swap'],

  methods: {
    swap() {
      this.$emit('swap')
    },
  },

  computed: {
    statTokens() {
      return [
        {
          iconName: 'cryptoDefaultIcon',
          label: 'SFCO',
          amount: this.SFCO,
        },
        {
          iconName: 'cryptoSFC',
          label: 'SFC',
          amount: this.SFC,
        },
        {
          iconName: 'cryptoDefaultIcon',
          label: 'SFC2',
          amount: this.SFC2,
        },
      ]
    },
  },
}
</script>
